import React from "react";
import ContactForm from "./ContactForm"
function ContactMe() {
  return (
    <div className="contact-me" id="iletişim">
        <ContactForm/>
    </div>
  );
}

export default ContactMe;
